<template>
  <div class="lang-selector">
    <div class="options">
      <ListItemCheck
        v-for="(lang, index) in langs"
        :key="index"
        :text="lang.name"
        icon="dot"
        :style="`--flag:url(${lang.flag})`"
        :checked="lang.code === current"
        @click="setLang(lang.code)"
      />
    </div>
  </div>
</template>

<script>
import LanguageSelect from "./LanguageSelect.vue";
export default {
  props: ["modal"],
  mixins: [LanguageSelect],
  data: function() {
    return {
      current: localStorage.getItem(process.env.VUE_APP_LANG_KEY) || process.env.VUE_APP_LANG_DEFAULT,
      langs: [
        {
          name: "English",
          code: "en",
          flag: "https://onegroup.s3.amazonaws.com/website/english_flag.svg",
        },
        {
          name: "Spanish",
          code: "es",
          flag: "https://onegroup.s3.amazonaws.com/website/spanish_flag.svg",
        },
      ],
    };
  },
  methods: {
    modalClose: function(cb) {
      this.$store.state.LanguageSelector = null;
      this.modal.close(cb);
    },
    setLang: function(lang) {
      this.modalClose(() => {
        this.setLanguage(lang);
      });
    },
  },
};
</script>

<style lang="scss">
$flagSize: $mpadding * 2.5;
.modal.ModalLanguageSelector {
  .modalTitleText {
    font-family: $third_font_bold;
    font-size: 110%;
  }
  .modal-logo svg {
    min-width: 105px;
  }
  .list-item {
    border-radius: $mradius;
    &-icon {
      width: $flagSize;
      height: $flagSize;
      min-width: $flagSize;
      min-height: $flagSize;
      max-width: $flagSize;
      max-height: $flagSize;
      background-color: #fff;
      border-radius: 50%;
      background-image: var(--flag);
      background-size: 170% !important;
      background-position: center center;
      box-shadow: $dshadow;
      border: solid 2px $primary_color;
      * {
        opacity: 0;
      }
    }
    &:hover,
    &:active {
      .mycheck.circle {
        border-color: $primary_color;
      }
    }
    &:not(:last-child) {
      margin-bottom: $mpadding/2;
    }
  }
  .modal-card-header-right {
    .button {
      padding: $mpadding $mpadding * 1.18 $mpadding $mpadding;
      .iconic {
        @include Flex(inherit, center, center);
        width: 34px;
        height: 34px;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius: 50%;
        //border: solid 1px;
      }
    }
  }
}
</style>
